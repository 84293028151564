import axios, { AxiosError, AxiosRequestConfig } from "axios"

import { dispatchedSetToken, dispatchedSetUser, showErrorNotification } from "@/redux/actions"

import { authService } from "./AuthService"

export type CustomError = {
  message?: string
  response?: {
    status: number
    data: { message: string }
  }
  errors?: { [key: string]: string[] }
}

axios.interceptors.request.use(
  (config) => {
    const token = authService.getToken()
    if (token && config?.headers) {
      config.headers["Authorization"] = "Bearer " + token
    }
    return config
  },
  (error: AxiosError) => {
    void Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (res) => res,
  (error: CustomError) => {
    try {
      if (error.response) {
        const {
          status,
          data: { message },
        } = error.response

        if (status === 401 && message === "Unauthenticated.") {
          authService.setCurrentUser(null)
          authService.setToken(null)
          dispatchedSetUser(null)
          dispatchedSetToken("")
        }
        showErrorNotification({ message: error.response.data.message })
        return Promise.reject(error.response.data)
      }
      showErrorNotification({ message: error.message || "" })
      return Promise.reject(error)
    } catch (e) {
      console.log("🚀 ~ file: BaseService.ts ~ line 41 ~ e", e)
    }
  }
)

export class ApiService {
  protected getCurrentUrl(path: string): string {
    if (process.env.REACT_APP_API_URL) {
      return `${process.env.REACT_APP_API_URL}/${path}`
    }

    return path
  }

  protected get headers(): { headers: { [key: string]: string } } {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    }
  }

  public async get<T>(route: string, customHeaders = {}) {
    const url = this.getCurrentUrl(route)
    const response = await axios.get<T>(url, { ...this.headers, ...customHeaders })
    return response?.data
  }

  public async post<T>(route: string, body, customHeaders = {}) {
    const url = this.getCurrentUrl(route)
    const response = await axios.post<T>(url, body, {
      headers: { ...this.headers.headers, ...customHeaders },
    })
    return response?.data
  }

  public async put<T>(route: string, body) {
    const url = this.getCurrentUrl(route)
    const response = await axios.put<T>(url, body, this.headers)
    return response?.data
  }

  public async patch<T>(route: string, body) {
    const url = this.getCurrentUrl(route)
    const response = await axios.patch<T>(url, body, this.headers)
    return response?.data
  }

  public async remove<T>(route: string, config?: AxiosRequestConfig) {
    const url = this.getCurrentUrl(route)
    const response = await axios.delete<T>(url, { ...config, headers: { ...this.headers.headers } })
    return response?.data || "Item removed"
  }
}

export const http = new ApiService()
