import { Customer } from "@/models"

export type Roles =
  | "customer"
  | "sm"
  | "coo"
  | "agency"
  | "business development"
  | "investor"
  | "financial manager"
  | "bnza acqm"
  | "onboarding specialist"

export type User = {
  api_token?: string
  avatar?: string
  email: string
  email_verified_at: string
  id: number
  first_name: string
  last_name: string
  status: string
  roles: Roles | Roles[]
  customers: {
    data: Customer[]
  }
  company?: number
  reason?: string
}

class AuthService {
  public getCurrentUser(): User | null {
    const user: User | null =
      localStorage.getItem("current_user") != null
        ? (JSON.parse(localStorage.getItem("current_user") || "{}") as User)
        : null
    return user
  }

  public getToken() {
    const token = localStorage.getItem("token") != null ? localStorage.getItem("token") || "" : null
    return token
  }

  public setCurrentUser(user?: User | null) {
    if (user) {
      localStorage.setItem("current_user", JSON.stringify(user))
    } else {
      localStorage.removeItem("current_user")
    }
  }

  public setToken(token: string | null) {
    if (token) {
      localStorage.setItem("token", token)
    } else {
      localStorage.removeItem("token")
    }
  }

  public getCurrentUserRole() {
    const user = this.getCurrentUser()
    if (!user) {
      return []
    }
    const { roles } = user
    return roles
  }

  public isCustomer(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "customer"
      : this.getCurrentUserRole().includes("customer")
  }

  public isNotCustomer(): boolean {
    return !this.isCustomer()
  }

  public isSM(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "sm"
      : this.getCurrentUserRole().includes("sm")
  }

  public isCOO(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "coo"
      : this.getCurrentUserRole().includes("coo")
  }

  public isAgency(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "agency"
      : this.getCurrentUserRole().includes("agency")
  }

  public isBusinessDevelopment(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "business development"
      : this.getCurrentUserRole().includes("business development")
  }

  public isInvestor(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "investor"
      : this.getCurrentUserRole().includes("investor")
  }

  public isFinancialManager(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "financial manager"
      : this.getCurrentUserRole().includes("financial manager")
  }

  public isSalesTeam(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "bnza acqm"
      : this.getCurrentUserRole().includes("bnza acqm")
  }

  public isOnboardingSpecialist(): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === "onboarding specialist"
      : this.getCurrentUserRole().includes("onboarding specialist")
  }

  public isOfRole(role: Roles): boolean {
    return typeof this.getCurrentUserRole() === "string"
      ? this.getCurrentUserRole() === role
      : this.getCurrentUserRole().includes(role)
  }
  public isOfRoles(roles: Roles[]): boolean {
    const currentUserRole = this.getCurrentUserRole()
    if (typeof currentUserRole === "string") {
      return roles.includes(currentUserRole)
    }
    return !!roles.map((role) => currentUserRole.includes(role)).filter((result) => !!result).length
  }

  public getCurrentUserCompany(): number {
    const user = this.getCurrentUser()
    if (!user) {
      return 0
    }
    const { customers } = user
    return customers.data[0].id
  }
}

export const authService = new AuthService()
