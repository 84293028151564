import { combineReducers } from "redux"

import { authReducer } from "./auth/slice"
import { customerReducer } from "./customer/slice"
import { financesReducer } from "./finances/slice"
import { menuReducer } from "./menu/slice"
import { notificationsReducer } from "./notifications"
import { settingsReducer } from "./settings/slice"
import { todosReducer } from "./todo/slice"

export const reducers = combineReducers({
  menu: menuReducer,
  settings: settingsReducer,
  authUser: authReducer,
  todoApp: todosReducer,
  finances: financesReducer,
  notifications: notificationsReducer,
  customer: customerReducer,
})
