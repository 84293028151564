import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"

import { authService, http } from "@/services"
import { Roles, User } from "@/services/AuthService"

export type AuthState = {
  currentUser: User | null
  token: string | null
  avatar: string
  forgotUserMail: string
  newPassword: string
  resetPasswordCode: string
  loading: boolean
  error: string
}

const initialState: AuthState = {
  currentUser: null,
  token: null,
  avatar: "",
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  error: "",
}

export const loginUser = createAsyncThunk<
  { user: User; token: string },
  { email: string; password: string },
  { rejectValue: string }
>("auth/loginUser", async ({ email, password }, { rejectWithValue }) => {
  try {
    const res = await http.post<{ token: string; roles: Roles[] }>("login", {
      email,
      password,
    })
    const { token, roles } = res

    if (roles?.some((userRole) => userRole === "customer")) {
      return rejectWithValue("Customers don't have access to admin dashboard")
    }
    authService.setToken(token)

    try {
      const { data } = await http.get<{ data: User }>("user")
      authService.setCurrentUser(data)
      return { user: data, token }
    } catch (error) {
      return rejectWithValue("Can't get user data")
    }
  } catch (error) {
    return rejectWithValue((error as Error).message)
  }
})

// export const forgotPassword = createAsyncThunk<User, string>(
//   "auth/forgotPassword",
//   async (email) => await forgotPasswordAsync(email)
// )

// export const resetPassword = createAsyncThunk(
//   "auth/resetPassword",
//   async ({ newPassword, resetPasswordCode }) =>
//     await resetPasswordAsync(resetPasswordCode, newPassword)
// )

export const registerUser = createAsyncThunk<
  User,
  { email: string; password: string; token: string; firstname: string; lastname: string }
>("auth/registerUser", async (user) => {
  const { email, password, token, firstname, lastname } = user
  return await http.post<User>("user/invite/accept", {
    email,
    password,
    token,
    firstname,
    lastname,
  })
})

export const logoutUser = createAsyncThunk<void>("auth/logoutUser", () =>
  http.post<{ message: string }>("logout", {}).then(() => {
    authService.setCurrentUser(null)
    authService.setToken(null)
  })
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => ({
      ...state,
      currentUser: payload,
    }),
    setAvatar: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      avatar: payload,
    }),
    setToken: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      token: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => ({
      ...state,
      currentUser: payload.user,
      token: payload.token,
      loading: false,
    }))
    builder.addCase(loginUser.rejected, (state, { payload }) => ({
      ...state,
      currentUser: null,
      token: "",
      loading: false,
      error: !!payload ? payload : "",
    }))
    builder.addCase(loginUser.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    // builder.addCase(forgotPassword.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   forgotUserMail: payload,
    //   error: "",
    //   loading: false,
    // }))
    // builder.addCase(forgotPassword.rejected, (state, { payload }) => ({
    //   ...state,
    //   error: payload?.message,
    //   forgotUserMail: "",
    //   loading: false,
    // }))
    // builder.addCase(forgotPassword.pending, (state) => ({
    //   ...state,
    //   error: "",
    //   loading: true,
    // }))

    // builder.addCase(resetPassword.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   newPassword: payload,
    //   resetPasswordCode: "",
    //   error: "",
    //   loading: false,
    // }))
    // builder.addCase(resetPassword.rejected, (state, { payload }) => ({
    //   ...state,
    //   error: payload?.message,
    //   newPassword: "",
    //   resetPasswordCode: "",
    //   loading: false,
    // }))
    // builder.addCase(resetPassword.pending, (state) => ({
    //   ...state,
    //   error: "",
    //   loading: true,
    // }))

    builder.addCase(registerUser.fulfilled, (state, { payload }) => ({
      ...state,
      currentUser: payload,
      error: "",
      loading: false,
    }))
    builder.addCase(registerUser.rejected, (state) => ({
      ...state,
      currentUser: null,
      loading: false,
    }))
    builder.addCase(registerUser.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(logoutUser.fulfilled, (state) => ({
      ...state,
      currentUser: null,
      token: "",
      error: "",
      loading: false,
    }))
    builder.addCase(logoutUser.rejected, (state) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(logoutUser.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))
  },
})

export const { setUser, setAvatar, setToken } = authSlice.actions

export const authReducer = authSlice.reducer
