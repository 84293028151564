import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { defaultLocale } from "@/constants/defaultValues"
import { setCurrentLanguage } from "@/helpers/Utils"

type SettingsState = {
  locale: string
}

export const initialState: SettingsState = {
  locale: defaultLocale,
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeLocale: {
      reducer: (state, action: PayloadAction<string>) => ({ ...state, locale: action.payload }),
      prepare: (locale: string) => {
        setCurrentLanguage(locale)
        return { payload: locale }
      },
    },
  },
})

export const settingsReducer = settingsSlice.reducer
export const { changeLocale } = settingsSlice.actions
