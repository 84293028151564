import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { http, authService } from "@/services"

import { CustomerWithTodos, RawTodo, Tag, Todo, TodoState } from "./types"

export const initialState: TodoState = {
  allTodoItems: [],
  todoItems: [],
  currentTodo: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  selectedCompany: "",
  labels: [
    { label: "EDUCATION", color: "secondary" },
    { label: "NEW FRAMEWORK", color: "primary" },
    { label: "PERSONAL", color: "info" },
  ],
  orderColumns: [
    { column: "name", label: "Title" },
    { column: "status", label: "Status" },
    { column: "tags", label: "Tag" },
  ],
  categories: ["Flexbox", "Sass", "React"],
  selectedItems: [],
  tags: [],
}

export const getTodoList = createAsyncThunk<Todo[], void, { rejectValue: string }>(
  "todos/getTodoList",
  async (_, { rejectWithValue }) => {
    let endP: string
    if (authService.isCustomer()) {
      endP = `customer/${authService.getCurrentUserCompany()}?with=todos`
    } else {
      endP = "user?with=customerTodos"
    }

    return http
      .get<{ data: { todos: { data: Todo[] }; customerTodos: { data: Todo[] } } }>(endP)
      .then(({ data }) => (authService.isCustomer() ? data.todos.data : data.customerTodos.data))
      .catch((error: string) => rejectWithValue(error))
  }
)

export const addTodoItem = createAsyncThunk<Todo, RawTodo, { rejectValue: string }>(
  "todos/addTodoItem",
  async (item, { rejectWithValue }) =>
    http
      .post<{ data: Todo[] }>("todo", item)
      .then(({ data }) => data[0])
      .catch((error: string) => rejectWithValue(error))
)

export const updateTodoItem = createAsyncThunk<Todo, RawTodo, { rejectValue: string }>(
  "todos/updateTodoItem",
  async (item, { rejectWithValue }) =>
    http
      .put<{ data: Todo[] }>(`todo/${item?.id || ""}`, item)
      .then(({ data }) => data[0])
      .catch((error: string) => rejectWithValue(error))
)

export const getTodoItem = createAsyncThunk<Todo, string, { rejectValue: string }>(
  "todos/getTodoItem",
  async (id, { rejectWithValue }) =>
    http
      .get<{ data: Todo }>(`todo/${id}`)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getLibraryTodoList = createAsyncThunk<Todo[], void, { rejectValue: string }>(
  "todos/getLibraryTodoList",
  async (_, { rejectWithValue }) =>
    http
      .get<{ data: Todo[] }>("todo-library?with=tags")
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getLibraryTodoItem = createAsyncThunk<Todo, string, { rejectValue: string }>(
  "todos/getLibraryTodoItem",
  async (id, { rejectWithValue }) =>
    http
      .get<{ data: Todo }>(`todo-library/${id}?with=tags`)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const addLibraryTodoItem = createAsyncThunk<Todo, RawTodo, { rejectValue: string }>(
  "todos/addLibraryTodoItem",
  async (item, { rejectWithValue }) =>
    http
      .post<{ data: Todo }>("todo-library?with=tags", item)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const updateLibraryTodoItem = createAsyncThunk<Todo, RawTodo, { rejectValue: string }>(
  "todos/updateLibraryTodoItem",
  async (item, { rejectWithValue }) =>
    http
      .put<{ data: Todo }>(`todo-library/${item?.id || ""}?with=tags`, item)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getTagList = createAsyncThunk<Tag[], void, { rejectValue: string }>(
  "todos/getTagList",
  async (_, { rejectWithValue }) =>
    http
      .get<{ data: Tag[] }>("tag")
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getCustomerWithTodos = createAsyncThunk<
  CustomerWithTodos,
  number,
  { rejectValue: string }
>("todos/getCustomerWithTodos", async (id, { rejectWithValue }) =>
  http
    .get<{ data: CustomerWithTodos }>(`customer/${id}?with=todos`)
    .then(({ data }) => data)
    .catch((error: string) => rejectWithValue(error))
)

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    getTodoListWithFilter: {
      reducer: (
        state,
        { payload: { column, value } }: { payload: { column: keyof Todo | ""; value: string } }
      ) => {
        if (column === "" || value === "") {
          return {
            ...state,
            loading: false,
            todoItems: state.allTodoItems,
            filter: null,
          }
        }
        const filteredItems =
          state?.allTodoItems?.length > 0
            ? state.allTodoItems.filter((item) => item[column] === value)
            : []
        return {
          ...state,
          loading: false,
          todoItems: filteredItems,
          filter: {
            column,
            value,
          },
        }
      },
      prepare: (column: keyof Todo | "", value: string) => ({ payload: { column, value } }),
    },
    getTodoListWithTagFilter: {
      reducer: (
        state,
        { payload: { column, value } }: { payload: { column: keyof Todo | ""; value: string } }
      ) => {
        if (column === "" || value === "") {
          return {
            ...state,
            loading: false,
            todoItems: state.allTodoItems,
            filter: null,
          }
        }
        const filteredItems =
          state?.allTodoItems?.length > 0
            ? state.allTodoItems.filter((item) => item.tags.data.some((tag) => tag.id === value))
            : []
        return {
          ...state,
          loading: false,
          todoItems: filteredItems,
          filter: {
            column,
            value,
          },
        }
      },
      prepare: (column: keyof Todo | "", value: string) => ({ payload: { column, value } }),
    },
    getTodoListWithOrder: (state, { payload }: { payload: keyof Todo | "" }) => {
      if (!payload) {
        return {
          ...state,
          loading: false,
          todoItems: state.allTodoItems,
          orderColumn: null,
        }
      }

      const sortedItems = [...state?.allTodoItems]

      if (sortedItems?.length > 0) {
        sortedItems.sort((a, b) => {
          const first = a[payload]
          const second = b[payload]
          if (!first || !second) {
            return 0
          }
          if (first < second) return -1
          if (first > second) return 1

          return 0
        })
      }
      return {
        ...state,
        loading: false,
        todoItems: sortedItems,
        orderColumn: state.orderColumns.find((x) => x.column === payload) || null,
      }
    },
    getTodoListSearch: (state, { payload }: { payload: string }) => {
      if (payload === "") {
        return { ...state, todoItems: state.allTodoItems }
      }
      const keyword = payload.toLowerCase()
      const searchItems =
        state?.allTodoItems?.length > 0
          ? state.allTodoItems.filter(
              (item) =>
                item.name.toLowerCase().indexOf(keyword) > -1 ||
                item.description.toLowerCase().indexOf(keyword) > -1 ||
                item.status.toLowerCase().indexOf(keyword) > -1
            )
          : []
      return {
        ...state,
        loading: false,
        todoItems: searchItems,
        searchKeyword: payload,
      }
    },
    selectedTodoItemsChange: (state, { payload }: { payload: string[] }) => ({
      ...state,
      loading: false,
      selectedItems: payload,
    }),
    getLibraryTodoListSearch: (state, { payload }: { payload: string }) => {
      if (payload === "") {
        return { ...state, todoItems: state.allTodoItems }
      }
      const keyword = payload.toLowerCase()
      const searchItems =
        state?.allTodoItems?.length > 0
          ? state.allTodoItems.filter(
              (item) =>
                item.name.toLowerCase().indexOf(keyword) > -1 ||
                item.description.toLowerCase().indexOf(keyword) > -1
            )
          : []
      return {
        ...state,
        loading: false,
        todoItems: searchItems,
        searchKeyword: payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTodoList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: payload,
      todoItems: payload,
      selectedCompany: "",
    }))
    builder.addCase(getTodoList.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getTodoList.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(addTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: [...state.allTodoItems, payload],
      todoItems: [...state.todoItems, payload],
    }))
    builder.addCase(addTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(addTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(updateTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: state.allTodoItems
        ? [...state.allTodoItems.filter((item) => item.id !== payload.id), payload]
        : [payload],
      todoItems: state.todoItems
        ? [...state.todoItems.filter((item) => item.id !== payload.id), payload]
        : [payload],
      currentTodo: payload,
    }))
    builder.addCase(updateTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(updateTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      currentTodo: payload,
    }))
    builder.addCase(getTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getLibraryTodoList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: payload,
      todoItems: payload,
      selectedCompany: "",
    }))
    builder.addCase(getLibraryTodoList.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getLibraryTodoList.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getLibraryTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      currentTodo: payload,
    }))
    builder.addCase(getLibraryTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getLibraryTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(addLibraryTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: [...state.allTodoItems, payload],
      todoItems: [...state.todoItems, payload],
    }))
    builder.addCase(addLibraryTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(addLibraryTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(updateLibraryTodoItem.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      allTodoItems: [payload, ...state.allTodoItems.filter((item) => item.id !== payload.id)],
      todoItems: [payload, ...state.todoItems.filter((item) => item.id !== payload.id)],
    }))
    builder.addCase(updateLibraryTodoItem.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(updateLibraryTodoItem.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getTagList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      tags: payload,
    }))
    builder.addCase(getTagList.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getTagList.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(
      getCustomerWithTodos.fulfilled,
      (state, { payload: { todos, company_name } }) => ({
        ...state,
        loading: false,
        allTodoItems: todos.data,
        todoItems: todos.data,
        selectedCompany: company_name,
      })
    )
    builder.addCase(getCustomerWithTodos.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getCustomerWithTodos.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))
  },
})

export const {
  getTodoListWithFilter,
  getTodoListWithTagFilter,
  getTodoListWithOrder,
  getTodoListSearch,
  selectedTodoItemsChange,
  getLibraryTodoListSearch,
} = todosSlice.actions

export const todosReducer = todosSlice.reducer
