import { lazy, Suspense } from "react"

export const Loadable = (loader: () => Promise<React.ComponentType>) => {
  const Loader = lazy(() =>
    loader().then((res) => ({
      default: res,
    }))
  )
  const component = (props) => (
    <Suspense fallback={<div className="loading" />}>
      <Loader {...props} />
    </Suspense>
  )
  return component
}
