export const DESCENDANT = "descendant"

export type CommonGoogleAccountItem = {
  type: "googleAccountInfo"
  id: number
  status: boolean
  version: string | null
  parent_id: number | null
  account_id: string | null
  account_name: string | null
  property_id: string | null
  property_name: string | null
  view_id: string | null
  view_name: string | null
  descendant?: CommonGoogleAccountItem[]
}

export type FbAccountItemType = {
  type: "fbAccountInfo"
  id: number
  account_id: string
  account_name: string
  is_active: boolean
  last_fetched: string
}
