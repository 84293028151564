/* eslint-disable @typescript-eslint/prefer-regexp-exec */
import { DateTime } from "luxon"
import { Dispatch, SetStateAction } from "react"

import {
  currencyToLocale,
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from "@/constants/defaultValues"
import { Customer, Payout } from "@/models"
import { CommonGoogleAccountItem, DESCENDANT } from "@/models/onboarding"
import { showErrorNotification, showSuccessNotification } from "@/redux/actions"
import { authService, http } from "@/services"

type TimeUnits = "year" | "month" | "week" | "day" | "hour" | "minute" | "second"

const DIRECTION = "direction"
const RTL = "rtl"
const LTR = "ltr"
const FLAT = "flat"
const CURRENT_LANGUAGE = "currentLanguage"
const ALL = "all"
const IMAGE_TYPES = ["jpeg", "jpg", "png"]
const DEFAULT_LANGUAGE = "en"

export const formatDate = (argDate = "") => {
  let date: Date
  if (argDate) {
    date = new Date(argDate)
  } else {
    date = new Date()
  }
  let dd: number | string = date.getDate()
  let mm: number | string = date.getMonth() + 1 // January is 0!

  const yyyy = date.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${dd}-${mm}-${yyyy}`
}

export const formatAPIDate = (date = "") => {
  if (date) {
    return new Date(date).toISOString().slice(0, 10).split("/").reverse().join("-")
  }
  return new Date().toISOString().slice(0, 10).split("/").join("-")
}

export const getDirection = () => {
  let direction = defaultDirection

  try {
    if (localStorage.getItem(DIRECTION)) {
      const localValue = localStorage.getItem(DIRECTION)
      if (localValue === RTL || localValue === LTR) {
        direction = localValue
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection
  }
  return {
    direction,
    isRtl: direction === RTL,
  }
}

export const setDirection = (localValue: string) => {
  let direction = LTR
  if (localValue === RTL || localValue === LTR) {
    direction = localValue
  }
  try {
    localStorage.setItem("direction", direction)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
}

export const getCurrentColor = () => {
  let currentColor = defaultColor
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey) || ""
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor
}

export const setCurrentColor = (color: string) => {
  try {
    localStorage.setItem(themeColorStorageKey, color)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
}

export const getCurrentRadius = () => {
  let currentRadius = FLAT
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey) || ""
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = FLAT
  }
  return currentRadius
}

export const getCurrentLanguage = () => {
  let language = defaultLocale
  try {
    const localLanguage = localStorage.getItem(CURRENT_LANGUAGE)
    if (!localLanguage) {
      return language
    } else {
      language =
        localeOptions.filter((x) => x.id === localStorage.getItem(CURRENT_LANGUAGE)).length > 0
          ? localLanguage
          : defaultLocale
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale
  }
  return language
}

export const setCurrentLanguage = (locale: string) => {
  try {
    localStorage.setItem(CURRENT_LANGUAGE, locale)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
}

export const getUserTokenIncludeCredentials = () => {
  const token = authService.getToken()

  return {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token || ""}`,
    },
  }
}

export const tableSearch = (
  e: { target: { value: string } },
  setError: Dispatch<SetStateAction<string>>,
  setSearchResult: Dispatch<SetStateAction<string[] | { [key: string]: unknown }[] | Customer[]>>,
  data: ({ [key: string]: unknown } | Customer)[],
  searchField: string | string[]
) => {
  setError("")
  let results: { [key: string]: unknown }[]
  if (e.target.value) {
    if (!Array.isArray(searchField)) {
      results = data.filter((item) =>
        (item[searchField] as string)?.toLowerCase().match(e.target.value.toLowerCase())
      )
    } else {
      results = data.filter((item) =>
        searchField.some((field) =>
          (item[field] as string)?.toLowerCase().match(e.target.value.toLowerCase())
        )
      )
    }
    if (!results.length) {
      setError("Not found")
    } else {
      setSearchResult(results)
    }
  } else {
    setSearchResult(data)
  }
}

export const tableFilter = (
  e: { value: string },
  setError: Dispatch<SetStateAction<string>>,
  setSearchResult: Dispatch<SetStateAction<{ [key: string]: string }[]>>,
  data: { [key: string]: string }[]
) => {
  setError("")
  if (e.value !== ALL) {
    const results = data.filter((item) => item?.status?.toLowerCase().match(e.value.toLowerCase()))
    if (!results.length) {
      setError("Not found")
    } else {
      setSearchResult(results)
    }
  } else {
    setSearchResult(data)
  }
}

export const checkForFormDateChange = (baseDate: string, formDate: string) =>
  baseDate?.slice(0, 10) === formDate ? baseDate : formDate

export const roundToHundredth = (num) => Math.round(num * 100) / 100

export const formatCurrency = (options: { currency_code: string; value: number }) =>
  new Intl.NumberFormat(currencyToLocale[options.currency_code] as string, {
    style: "currency",
    currency: options.currency_code,
  }).format(options.value)

export const numberFormatter = (number: number): string => Intl.NumberFormat().format(number)

export function checkIfFilesAreCorrectType(file: string) {
  let valid = true
  if (file) {
    const dotPosition = file.indexOf(".")
    const ext = file.slice(dotPosition + 1)
    if (!IMAGE_TYPES.includes(ext)) {
      valid = false
    }
  }
  return valid
}

export const convertServerErrorsToFieldErrors = (errorsObj: { [key: string]: string[] }) => {
  const convertedObject = Object.keys(errorsObj).reduce(
    (acc, field) => ({ ...acc, [field]: errorsObj[field][0] }),
    {}
  )
  let parsedObject = {}
  const values = Object.values(convertedObject)
  Object.keys(convertedObject).forEach((key, index) => {
    if (!key.includes(".")) {
      parsedObject[key] = values[index]
    } else {
      const parsedNameList = key.split(".")
      if (parsedNameList.length === 2) {
        parsedObject = {
          ...parsedObject,
          [parsedNameList[0]]: {
            [parsedNameList[1]]: values[index],
          },
        }
      } else if (parsedNameList.length === 3) {
        parsedObject = {
          ...parsedObject,
          [parsedNameList[0]]: {
            [parsedNameList[1]]: {
              [parsedNameList[2]]: values[index],
            },
          },
        }
      }
    }
  })

  return parsedObject
}

export const isGrowthPack = (value) => value === "growthPack"
export const isGrowthLoan = (value) => value === "growthLoan"
export const isOptimizedMicrofunding = (value) => value === "optimizerMicrofunding"
export const isOptimizer = (value) => value === "optimizer"
export const isLoanAdjustment = (value) => value === "loanAddendumAgreement"
export const isStatusCompleted = (value: string) => value?.toLowerCase() === "completed"
export const isStatusPending = (value: string) => value?.toLowerCase() === "pending"
export const showApproveButton = (value: Payout["status"]) =>
  value.toLowerCase() === "pending" ||
  value.toLowerCase() === "in_review" ||
  value.toLowerCase() === "additional_info_needed" ||
  value.toLowerCase() === "in_progress"
export const canChangeStatus = (value: string) =>
  value.toLowerCase() !== "approved" &&
  value.toLowerCase() !== "denied" &&
  value.toLowerCase() !== "completed"

export const defineValueType = (value: string) => {
  if (!value) return "fixed"
  const definitionsArr = value.split("_")
  return definitionsArr.length > 1 ? "dynamic" : "fixed"
}

export const dateSort = <T extends { created_at: string }>(array: T[]) =>
  array.sort((b, a) => {
    const aDate = new Date(a.created_at)
    const bDate = new Date(b.created_at)

    if (aDate > bDate) {
      return 1
    }
    if (aDate < bDate) {
      return -1
    }
    return 0
  })

const units: TimeUnits[] = ["year", "month", "week", "day", "hour", "minute", "second"]

export const relativeDateFormatter = (date: string) => {
  const dateTime = DateTime.fromISO(date)
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second"

  const relativeFormatter = new Intl.RelativeTimeFormat(DEFAULT_LANGUAGE, {
    numeric: "auto",
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}

export const getClients =
  (setClients: Dispatch<SetStateAction<Customer[]>>) => (search?: string) => {
    http
      .get<{ data: Customer[] }>(`user/customers?page=1${search ? `&search=${search}` : ""}`)
      .then(({ data }) => {
        setClients(data)
      })
      .catch(({ message }: Error) => showErrorNotification({ message }))
  }

export const applyPhoneNumberMask = (phone: string) =>
  `${phone.slice(0, 2)}********${phone.slice(-4)}`

export const removeEmpty = (obj: { [key: string]: unknown }) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyAccountFields = (obj: { [key: string]: any }): CommonGoogleAccountItem =>
  Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value != null)
      .map(([name, value]: [string, CommonGoogleAccountItem[]]) => {
        if (name === DESCENDANT && !!value && typeof value === "object") {
          if (value?.length === 0) {
            return []
          }
          const newData = value.map((item: CommonGoogleAccountItem) =>
            removeEmptyAccountFields(item)
          )
          return [name, newData]
        }
        return [name, value]
      })
  ) as CommonGoogleAccountItem

export const convertResponseToAccountsList = (response: CommonGoogleAccountItem[]) =>
  response.map((accountItem) => removeEmptyAccountFields(accountItem))

export const copyToClipboard = (
  textToCopy: string | null,
  showSuccessMsg: boolean,
  formatMessage: ({ id }: { id: string }) => string
) => {
  void navigator.clipboard.writeText(textToCopy || "")
  showSuccessMsg &&
    showSuccessNotification({
      message: formatMessage({ id: "notifications.linkCopied" }),
      title: formatMessage({ id: "notifications.copy" }),
    })
}
