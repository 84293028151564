/* eslint-disable global-require */
import "./assets/css/vendor/bootstrap.min.css"
import "./assets/css/vendor/bootstrap.rtl.only.min.css"
import { isMultiColorActive, defaultColor, isDarkSwitchActive } from "./constants/defaultValues"
import { getCurrentColor, setCurrentColor } from "./helpers/Utils"

const color = isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor
setCurrentColor(color)

const render = () => {
  void import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require("./AppRenderer")
  })
}
render()
