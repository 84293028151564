import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { Customer } from "@/models"
import { http } from "@/services"

export const initialState: { currentCustomer: Customer | null } = { currentCustomer: null }

export const getCustomer = createAsyncThunk<Customer, string, { rejectValue: string }>(
  "customer/getCustomer",
  async (customerId, { rejectWithValue }) =>
    http
      .get<{ data: Customer }>(`customer/${customerId}`)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomer.fulfilled, (_, { payload }: { payload: Customer | null }) => ({
      currentCustomer: payload,
    }))
  },
})

export const customerReducer = customerSlice.reducer
