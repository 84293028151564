import {
  loginUser,
  logoutUser,
  registerUser,
  setAvatar,
  setToken,
  setUser,
} from "@/redux/auth/slice"
import {
  changeSubItemsStatus,
  setClassNames,
  addClassName,
  clickMobileMenu,
} from "@/redux/menu/slice"
import {
  addLibraryTodoItem,
  addTodoItem,
  getCustomerWithTodos,
  getLibraryTodoItem,
  getLibraryTodoList,
  getLibraryTodoListSearch,
  getTagList,
  getTodoItem,
  getTodoList,
  getTodoListSearch,
  getTodoListWithFilter,
  getTodoListWithOrder,
  getTodoListWithTagFilter,
  selectedTodoItemsChange,
  updateLibraryTodoItem,
  updateTodoItem,
} from "@/redux/todo/slice"
import { User } from "@/services/AuthService"

import { getCustomer } from "./customer/slice"
import {
  Card,
  getCards,
  getFinances,
  getLoans,
  updateCards,
  setFinances,
  CustomerWithFinances,
  setCards,
  setLoans,
  Loan,
} from "./finances"
import { NotificationType, addNotification, removeNotification } from "./notifications"
import { changeLocale } from "./settings/slice"
import { store } from "./store"
import { RawTodo, Todo } from "./todo/types"

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE"

export * from "@/redux/menu/slice"
export * from "@/redux/todo/slice"
export * from "@/redux/auth/slice"

export const dispatchedGetTodoList = () => store.dispatch(getTodoList())
export const dispatchedAddTodoItem = (item: RawTodo) => store.dispatch(addTodoItem(item))
export const dispatchedUpdateTodoItem = (item: RawTodo) => store.dispatch(updateTodoItem(item))
export const dispatchedGetTodoItem = (id: string) => store.dispatch(getTodoItem(id))
export const dispatchedGetLibraryTodoList = () => store.dispatch(getLibraryTodoList())
export const dispatchedGetLibraryTodoItem = (id: string) => store.dispatch(getLibraryTodoItem(id))
export const dispatchedAddLibraryTodoItem = (item: RawTodo) =>
  store.dispatch(addLibraryTodoItem(item))
export const dispatchedUpdateLibraryTodoItem = (item: RawTodo) =>
  store.dispatch(updateLibraryTodoItem(item))
export const dispatchedGetTagList = () => store.dispatch(getTagList())
export const dispatchedGetCustomerWithTodos = (id: number) =>
  store.dispatch(getCustomerWithTodos(id))
export const dispatchedGetTodoListWithFilter = (column: keyof Todo | "", value: string) =>
  store.dispatch(getTodoListWithFilter(column, value))
export const dispatchedGetTodoListWithTagFilter = (column: keyof Todo | "", value: string) =>
  store.dispatch(getTodoListWithTagFilter(column, value))
export const dispatchedGetTodoListWithOrder = (column: keyof Todo) =>
  store.dispatch(getTodoListWithOrder(column))
export const dispatchedGetTodoListSearch = (search: string) =>
  store.dispatch(getTodoListSearch(search))
export const dispatchedSelectedTodoItemsChange = (items: string[]) =>
  store.dispatch(selectedTodoItemsChange(items))
export const dispatchedGetLibraryTodoListSearch = (search: string) =>
  store.dispatch(getLibraryTodoListSearch(search))

export const dispatchedGetFinances = (id: string) => store.dispatch(getFinances(id))
export const dispatchedSetFinances = (customer: CustomerWithFinances) =>
  store.dispatch(setFinances(customer))
export const dispatchedGetLoans = (id: string) => store.dispatch(getLoans(id))
export const dispatchedGetCards = (id: string) => store.dispatch(getCards(id))
export const dispatchedSetCards = (cards: Card[]) => store.dispatch(setCards(cards))
export const dispatchedUpdateCards = (card: Card) => store.dispatch(updateCards(card))
export const dispatchedSetLoans = (loans: Loan[]) => store.dispatch(setLoans(loans))

export const dispatchedLoginUser = (loginData: { email: string; password: string }) =>
  store.dispatch(loginUser(loginData))
export const dispatchedRegisterUser = (registerData: {
  email: string
  password: string
  token: string
  firstname: string
  lastname: string
}) => store.dispatch(registerUser(registerData))
export const dispatchedLogoutUser = () => store.dispatch(logoutUser())
export const dispatchedSetUser = (payload: User | null) => store.dispatch(setUser(payload))
export const dispatchedSetAvatar = (avatar: string) => store.dispatch(setAvatar(avatar))
export const dispatchedSetToken = (token: string) => store.dispatch(setToken(token))

export const dispatchedChangeSubItemsStatus = (status: boolean) =>
  store.dispatch(changeSubItemsStatus(status))
export const dispatchedSetClassNames = (
  clickIndex: number,
  strCurrentClasses: string,
  selectedMenuHasSubItems: boolean
) => store.dispatch(setClassNames(clickIndex, strCurrentClasses, selectedMenuHasSubItems))
export const dispatchedAddClassName = (classname: string, strCurrentClasses: string) =>
  store.dispatch(addClassName(classname, strCurrentClasses))
export const dispatchedClickMobileMenu = (strCurrentClasses: string) =>
  store.dispatch(clickMobileMenu(strCurrentClasses))

export const dispatchedChangeLocale = (locale: string) => store.dispatch(changeLocale(locale))
export const dispatchedGetCustomer = (customerId: string) => store.dispatch(getCustomer(customerId))

export const showNotification = (
  notification: Pick<
    NotificationType,
    "type" | "message" | "title" | "timeOut" | "priority" | "onClick" | "customClassName"
  >
) => {
  const defaultNotification: NotificationType = {
    id: Math.round(Math.random() * 100).toString(),
    type: "info",
    title: "",
    message: "",
    timeOut: 3000,
    customClassName: "",
  }

  const filledNotification = Object.assign(defaultNotification, notification)

  store.dispatch(addNotification(filledNotification))
  setTimeout(() => {
    store.dispatch(removeNotification(filledNotification))
  }, filledNotification.timeOut)
}

export const showSuccessNotification = ({ title, message }: { message: string; title?: string }) =>
  showNotification({
    type: "success",
    title: title || "Success",
    message,
  })

export const showErrorNotification = ({ title, message }: { message: string; title?: string }) =>
  showNotification({
    type: "error",
    title: title || "Error",
    message,
  })
