import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { dispatchedGetFinances, dispatchedGetCards } from "@/redux/actions"
import { useAppSelector } from "@/redux/store"

export const useFetchFinances = () => {
  const params = useParams()
  const customerId = params.customerId || ""

  useEffect(() => {
    if (customerId) {
      void dispatchedGetFinances(customerId)
    }
  }, [customerId])
}

export const useCardsForSelect = () => {
  const params = useParams()
  const customerId = params.customerId || ""

  useEffect(() => {
    if (customerId) {
      void dispatchedGetCards(customerId)
    }
  }, [customerId])

  const userCards = useAppSelector(({ finances }) =>
    finances.cards?.map((card) => ({
      label: card.card_number,
      value: card.id.toString(),
      key: card.id.toString(),
    }))
  )

  return userCards
}
