import { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { Loadable } from "./helpers/Loadable"
import { store } from "./redux/store"
import * as serviceWorker from "./serviceWorker"

const App = Loadable(() => import(/* webpackChunkName: "App" */ "./App").then((res) => res.App))

const container = document.getElementById("root")
const appRoot = container && createRoot(container)

appRoot?.render(
  <Provider store={store}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>
)
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
