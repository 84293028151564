import { ApiService } from "./ApiService"

class Api2Service extends ApiService {
  protected getCurrentUrl(path: string): string {
    if (process.env.REACT_APP_API_V2_URL) {
      return `${process.env.REACT_APP_API_V2_URL}/${path}`
    }

    return path
  }
}

export const api2Http = new Api2Service()
