import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"

import { http } from "@/services"

import { Card, CustomerWithFinances, FinancesState, Loan } from "./types"

export const initialState: FinancesState = {
  loans: [],
  loansSummary: null,
  cards: [],
  cardsSummary: null,
  loading: false,
  error: "",
}

export const getFinances = createAsyncThunk<CustomerWithFinances, string, { rejectValue: string }>(
  "finances/fetchFinances",
  async (customerId, { rejectWithValue }) =>
    http
      .get<{ data: CustomerWithFinances }>(
        `customer/${customerId}?with=loans,loansSummary,cards,cardsSummary`
      )
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getLoans = createAsyncThunk<{ loans: Loan[] }, string, { rejectValue: string }>(
  "finances/fetchLoans",
  async (customerId, { rejectWithValue }) =>
    http
      .get<{ data: { loans: Loan[] } }>(`customer/${customerId}?with=loans`)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

export const getCards = createAsyncThunk<{ cards: Card[] }, string, { rejectValue: string }>(
  "finances/fetchCards",
  async (customerId, { rejectWithValue }) =>
    http
      .get<{ data: { cards: Card[] } }>(`customer/${customerId}?with=cards`)
      .then(({ data }) => data)
      .catch((error: string) => rejectWithValue(error))
)

const financesSlice = createSlice({
  name: "finances",
  initialState,
  reducers: {
    updateCards: (state, action: PayloadAction<Card>) => ({
      ...state,
      cards: [...state.cards.filter(({ id }) => id !== action.payload.id), action.payload],
    }),
    setCards: (state, action: PayloadAction<Card[]>) => ({
      ...state,
      cards: action.payload,
    }),
    setFinances: (state, { payload }: PayloadAction<CustomerWithFinances>) => ({
      ...state,
      loans: payload.loans,
      loansSummary: payload.loansSummary,
      cards: payload.cards,
      cardsSummary: payload.cardsSummary,
      loading: false,
      error: "",
    }),
    setLoans: (state, { payload }: PayloadAction<Loan[]>) => ({
      ...state,
      loans: payload,
      loading: false,
      error: "",
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFinances.fulfilled,
      (state, { payload: { loans, loansSummary, cards, cardsSummary } }) => ({
        loans: loans,
        loansSummary: loansSummary,
        cards: cards,
        cardsSummary: cardsSummary,
        loading: false,
        error: "",
      })
    )
    builder.addCase(getFinances.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getFinances.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getLoans.fulfilled, (state, { payload: { loans } }) => ({
      ...state,
      loans: loans,
      loading: false,
    }))
    builder.addCase(getLoans.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getLoans.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))

    builder.addCase(getCards.fulfilled, (state, { payload: { cards } }) => ({
      ...state,
      cards: cards,
      loading: false,
    }))
    builder.addCase(getCards.rejected, (state, { payload }) => ({
      ...state,
      error: !!payload ? payload : "",
      loading: false,
    }))
    builder.addCase(getCards.pending, (state) => ({
      ...state,
      error: "",
      loading: true,
    }))
  },
})

export const financesReducer = financesSlice.reducer
export const { updateCards, setFinances, setCards, setLoans } = financesSlice.actions
