export const defaultLocale = "en"
export const localeOptions = [
  { id: "en", name: "English - Left", direction: "ltr" },
  { id: "enrtl", name: "English - Right", direction: "rtl" },
]

const chatUrl = process.env.REACT_APP_CHAT_URL || ""
export const toChat = () => {
  const token = localStorage.getItem("token")
  return `${chatUrl}/instantlogin?token=${token || ""}`
}

export const apiUrl = process.env.REACT_APP_API_URL

export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/p-default-s.png",
  date: "Last seen today 15:24",
  role: "customer",
}

export const themeColorStorageKey = "__theme_selected_color"
export const isMultiColorActive = true
export const defaultColor = "light.purplemonster"
export const isDarkSwitchActive = true
export const defaultDirection = "ltr"
export const themeRadiusStorageKey = "__theme_radius"
export const isAuthGuardActive = true
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
]

export const settingsTabs = {
  user: "user",
  company: "company",
  password: "password",
}

export const availableCurrencies = [
  { label: "DKK", value: "DKK", key: "0" },
  { label: "SEK", value: "SEK", key: "1" },
  { label: "NOK", value: "NOK", key: "2" },
  { label: "EUR", value: "EUR", key: "3" },
  { label: "USD", value: "USD", key: "4" },
  { label: "GBP", value: "GBP", key: "5" },
]

export const availableProviders = [
  {
    label: "Farpay",
    value: "farpay",
    key: "0",
  },
  {
    label: "Manual",
    value: "manual",
    key: "1",
  },
]

export const currencyToLocale = {
  DKK: "da-DK",
  NOK: "nn-NO",
  SEK: "se-SE",
  GBP: "en-GB",
  USD: "en-US",
  EUR: "un-GB",
}

export const SPLIT_LOAN = "revenue-split-subscription-psp-loan"
export const GROWTH_LOAN_FLAT_FEE = "growth-loan-flat-fee"
export const GROWTH_PLAN = "growth-plan-growth-agreement-split-loan"

export const shopSystemOptions = [
  {
    label: "PSP Loan (3 tranch)",
    value: SPLIT_LOAN,
    key: "0",
  },
  {
    label: "Growth Loan Flat Fee",
    value: GROWTH_LOAN_FLAT_FEE,
    key: "1",
  },
  {
    label: "Growth Agreement Split Loan",
    value: GROWTH_PLAN,
    key: "2",
  },
]

export const onBehalfOf = [
  {
    label: "The Business",
    value: "The Business",
    key: "0",
  },
  {
    label: "The Owner",
    value: "The Owner",
    key: "1",
  },
  {
    label: "Both",
    value: "Both",
    key: "2",
  },
]

export const productTypes = [
  {
    name: "Growth Loan",
    value: "growthLoan",
    key: "0",
  },
  {
    name: "Growth Pack",
    value: "growthPack",
    key: "1",
  },
  {
    name: "Optimizer",
    value: "optimizer",
    key: "2",
  },
  {
    name: "Optimizer Microfunding",
    value: "optimizerMicrofunding",
    key: "3",
  },
]

export const imageTypes = [
  {
    label: "TBS",
    value: "tbs",
    key: "0",
  },
  {
    label: "Enclosure",
    value: "enclosure",
    key: "1",
  },
  {
    label: "Internal",
    value: "internal",
    key: "2",
  },
]

export const ruleSetIntervals = [
  { label: "Daily", value: "daily", key: "0" },
  { label: "Weekly", value: "weekly", key: "1" },
  { label: "Monthly", value: "monthly", key: "2" },
]

export const ruleSetTypes = [
  { label: "Recurring", value: "recurring", key: "0" },
  { label: "Single", value: "single", key: "1" },
]

export const loanRulesOperators = [
  { label: "Percent", value: "percent", key: "0" },
  { label: "Fixed", value: "fixed", key: "1" },
]

export const loanRulesValues = [
  { label: "Fixed value", value: "fixed", key: "0" },
  { label: "Dynamic definition", value: "dynamic", key: "1" },
]

export const loanRulesDefinitions = [
  { label: "Gross Revenue", value: "definition_grossRevenue", key: "0" },
]

export const loanRulesSettingsIntervals = [{ label: "Yesterday", value: "yesterday", key: "0" }]

export const loanRulesSettingsUntil = [
  {
    label: "Total principal amount is repaid",
    value: "total_principal_amount_is_repaid",
    key: "0",
  },
  {
    label: "Deposit amount is repaid",
    value: "deposit_amount_is_repaid",
    key: "1",
  },
  {
    label: "Fee amount is repaid with deposit left is 0",
    value: "fee_amount_is_repaid_with_deposit_left_is_0",
    key: "2",
  },
  {
    label: "Total amount is repaid with deposit left and fee left is 0",
    value: "total_amount_is_repaid_with_deposit_left_and_fee_left_is_0",
    key: "3",
  },
]

export const loanRulesSettingsTransactionsTypes = [
  {
    label: "MXNEY Loan Deposit",
    value: "mxney_loan_deposit",
    key: "0",
  },
  { label: "MXNEY Loan Repayment", value: "mxney_loan_repayment", key: "1" },
  { label: "Tranche fee", value: "tranche_fee", key: "2" },
]

export const loanRulesSettingsTypes = [
  { label: "Repayment", value: "repayment", key: "0" },
  { label: "Fee", value: "fee", key: "1" },
  { label: "Deposit", value: "deposit", key: "2" },
  { label: "Principal", value: "principal", key: "3" },
  { label: "Flat fee", value: "flat_fee", key: "4" },
]

export const loanRepaymentTypes = [
  { label: "Repayment", value: "repayment", key: "0" },
  { label: "Deposit", value: "deposit", key: "1" },
]

export const companyStatuses = (showNotActive: boolean) => {
  const statuses = [
    {
      label: "Onboarding",
      value: "onboarding",
      key: "0",
    },
    {
      label: "Active",
      value: "active",
      key: "1",
    },
    {
      label: "Pending offer",
      value: "pending_offer",
      key: "2",
    },
    {
      label: "In DD",
      value: "in_dd",
      key: "3",
    },
    {
      label: "Lead",
      value: "lead",
      key: "4",
    },
    {
      label: "Finalized",
      value: "finalized",
      key: "5",
    },
    {
      label: "In default",
      value: "in_default",
      key: "6",
    },
    {
      label: "Paused",
      value: "paused",
      key: "7",
    },
    {
      label: "Prepaid",
      value: "prepaid",
      key: "8",
    },
    {
      label: "Agency",
      value: "agency",
      key: "9",
    },
  ]
  if (showNotActive) {
    statuses.push({
      label: "Not active",
      value: "not_active",
      key: "10",
    })
  }
  return statuses
}

export const companyTiers = [
  {
    label: "Tier 1",
    value: 1,
    key: "1",
  },
  {
    label: "Tier 2",
    value: 2,
    key: "2",
  },
  {
    label: "Tier 3",
    value: 3,
    key: "3",
  },
]

export const userStatuses = [
  { label: "All", value: "all", key: "0" },
  { label: "Active", value: "active_customer", key: "1" },
  { label: "Finalized", value: "finalized", key: "2" },
  { label: "In DD", value: "in_dd", key: "3" },
  { label: "In default", value: "in_default", key: "4" },
  { label: "Lead", value: "lead", key: "5" },
  { label: "Onboarding", value: "onboarding", key: "6" },
  { label: "Paused", value: "paused", key: "7" },
  { label: "Pending offer", value: "pending_offer", key: "8" },
  { label: "Prepaid", value: "prepaid", key: "9" },
]

export const availablePlatforms = [
  { value: "shopify", label: "Shopify", key: "0" },
  { value: "woocommerce", label: "WooCommerce", key: "1" },
  { value: "prestashop", label: "PrestaShop", key: "2" },
  { value: "magento", label: "Magento", key: "3" },
  { value: "smartweb", label: "SmartWeb", key: "4" },
  { value: "shoporama", label: "Shoporama", key: "5" },
  { value: "dandomain", label: "DanDomain", key: "6" },
  { value: "abicart", label: "Abicart", key: "7" },
]

export const shopIndustries = [
  { value: "Beauty & Health", label: "Beauty & Health", key: "1" },
  { value: "Furniture/ Décor", label: "Furniture/ Décor", key: "2" },
  { value: "Kids Toys & Accessories", label: "Kids Toys & Accessories", key: "3" },
  { value: "Clothing & Apparel", label: "Clothing & Apparel", key: "4" },
  { value: "Supplements & Vitamins", label: "Supplements & Vitamins", key: "5" },
  { value: "Sunglasses & Accessories", label: "Sunglasses & Accessories", key: "6" },
  { value: "Sports Equipment", label: "Sports Equipment", key: "7" },
  { value: "Pet & Animal Supplies", label: "Pet & Animal Supplies", key: "8" },
  { value: "Kitchen Equipment & Supplies", label: "Kitchen Equipment & Supplies", key: "9" },
  { value: "Car Equipment", label: "Car Equipment", key: "10" },
  { value: "Jewelry", label: "Jewelry", key: "11" },
  { value: "Electronics", label: "Electronics", key: "12" },
  { value: "Food & Beverage", label: "Food & Beverage", key: "13" },
  { value: "Outdoor", label: "Outdoor", key: "14" },
  { value: "Other", label: "Other", key: "15" },
]

export const statusOptions = [
  { label: "started", value: "started", key: "0" },
  { label: "upcoming", value: "upcoming", key: "1" },
  { label: "on hold", value: "on hold", key: "2" },
  { label: "finished", value: "finished", key: "3" },
]

export const agreementStatuses = [
  { label: "Signed", value: "signed", key: "0" },
  { label: "Terminated", value: "terminated", key: "1" },
  { label: "Draft", value: "draft", key: "2" },
  { label: "Expired", value: "expired", key: "3" },
]

export const payoutStatusOptions = [
  { label: "Paid Out", value: "paid_out_to_customer", key: "0" },
  { label: "Info Needed", value: "additional_info_needed", key: "1" },
  { label: "In Review", value: "in_review", key: "2", disabled: true },
  { label: "Pending", value: "pending", key: "3", disabled: true },
  { label: "Not Completed", value: "not_completed", key: "4", disabled: true },
]

export const MEGABYTE_IN_BYTES = 1048576

export const onboardingLines = [
  { name: "connected_webshop", label: "Connected webshop", done: false },
  { name: "connected_facebookAds", label: "Connected Facebook", done: false },
  { name: "connected_googleAds", label: "Connected Google Ads", done: false },
  {
    name: "connected_googleAnalytics",
    label: "Connected Google Analytics",
    done: false,
  },
  { name: "connected_aiia", label: "Connected AIIA", done: false },
  { name: "connected_nordigen", label: "Connected Nordigen", done: false },
  {
    name: "connected_bank_statement",
    label: "Connected Bank Statement",
    done: false,
  },
  {
    name: "connected_company_info",
    label: "Connected Company Info",
    done: false,
  },
  { name: "connected_swan", label: "Connected Swan", done: false },
  { name: "direct_debit", label: "Direct Debit", done: false },
]

export const getOfferTypes = (disableOfferOptions?: boolean) => [
  { label: "New signing", value: "new-signing", key: "0", isDisabled: disableOfferOptions },
  { label: "Up-sell", value: "upsell", key: "1" },
  { label: "Conversion", value: "conversion", key: "2" },
  { label: "Growth Pack financing", value: "growth-pack-financing", key: "3" },
]
