import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type MessageTypes = "info" | "success" | "warning" | "error"

export type NotificationType = {
  type: MessageTypes
  message: string
  title: string
  timeOut?: number
  onClick?: () => void
  priority?: number
  customClassName?: string
  id?: string
}

export const initialState: NotificationType[] = []

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<NotificationType>) => {
      if (payload.priority) {
        return [payload, ...state]
      } else {
        return [...state, payload]
      }
    },
    removeNotification: (state, { payload }: PayloadAction<NotificationType>) =>
      state.filter((notification) => notification.id !== payload.id),
  },
})

export const notificationsReducer = notificationsSlice.reducer
export const { addNotification, removeNotification } = notificationsSlice.actions
